<div *ngFor="let item of assignmentData; let i = index"
     class="gs-suggestion-assignment-card-content"
     (click)="onWorkOrderSuggestionClick(item)">
  <div class="gs-suggestion-row">
    <div class="gs-suggestion-wo-info">
      {{item.InternalCompanyCode}} {{item.WorkOrderNumber}}
    </div>
    <div class="gs-suggestion-wo-period" *ngIf="item.StartDate || item.EndDate">
      {{item.StartDate | date:dateFormatMMM_ddComma_yyyy}} -
      {{item.EndDate | date:dateFormatMMM_ddComma_yyyy}} </div>
  </div>
  <br/>
  <div class="gs-suggestion-worker-info">{{item.WorkerDisplayName}}
    ({{getProfileTypeForDisplay(item.ProfileType) || '-'}})
  </div>
  <br/> 
  <div>
    <div class="gs-suggestion-assignment-clientname"><i class="fa fa-building"></i> {{item.ClientName}}</div>
    <div class="gs-suggestion-wo-period">
      <div class="circle" [ngClass]="{
            'active-status':item.AssignmentStatus === 'Engaged',
            'processing-status':item.AssignmentStatus === 'Onboarding' || item.AssignmentStatus === 'Offboarding',
            'complete-status':item.AssignmentStatus === 'Complete'
        }">
      </div>
      <span class="gs-suggestion-wo-period">
          {{item.AssignmentStatus || '-'}} [{{item.WOStatus || '-'}}]
      </span>
    </div>
  </div>
  <br/>
  <i class="fa fa-search searchicon"></i>
  <div [innerHtml]="item.SearchText" class="searchtext"></div>
</div>
