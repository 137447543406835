import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { PhxConstants } from '../../model';
import UserProfileType = PhxConstants.UserProfileType;

@Injectable()
export class CanActivateInternalProfileGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise(async (resolve, reject) => {
      try {
        const user = await this.authService.fetchCurrentProfile();
        const hasAccess = user?.ProfileTypeId === UserProfileType.Internal;

        if (!hasAccess) {
          this.router.navigate(['/next', 'no-access'], { replaceUrl: true });
        }

        return resolve(hasAccess);
      } catch (e) {
        reject(e);
      }
    });
  }
}
