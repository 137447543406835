import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Data, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { switchMap } from 'rxjs/operators';
import { PhxConstants } from '../PhoenixCommon.module';
import { ConfigurationService } from 'src/app/configuration/service/configuration.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class RoleAccessGuard implements CanActivate, CanActivateChild {

    constructor(
        private authService: AuthService,
        private router: Router,
        private configurationService: ConfigurationService
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.canAccess(route?.data);
    }

    canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (route.data?.validRoles) {
            return this.canAccess(route.data);
        } else if (route?.parent?.data) {
            return this.canAccess(route.parent.data);
        }
    }

    private canAccess(routeData: Data) {
        return this.authService.getCurrentProfile().pipe(
            switchMap(profile => forkJoin([
                this.configurationService.getFeatureFlagConfigurationData$(),
                of(profile)
            ])),
            switchMap(([flagData, profile]) => {
                if (!this.configurationService.isFeatureActive(PhxConstants.FeatureFlags.UseAccessByRoleGaurd)) {
                    return of(true);
                } else {
                    let hasAccess = false;
                    /** NOTE: get any role/operation access specifically assigned to this route */
                    const validRoleIds = routeData.validRoles || [];
                    const validFunctionalIds = routeData.validOperations || [];
                    /** NOTE: get current user's role and operation access */
                    const userRoleIds = profile.FunctionalRoles.map(m => m.FunctionalRoleId);
                    const userFunctionalIds = profile.FunctionalOperations;

                    if (routeData) {
                        /** NOTE: if there are no accepted role/operation passed with the route allow access  */
                        if (!validRoleIds.length && !validFunctionalIds.length) {
                            hasAccess = true;
                            /** NOTE: if user matches role or operation then we grant access - maybe there is an AND scenario - revisit */
                        } else if (validRoleIds.filter(f => userRoleIds.includes(f)).length || validFunctionalIds.filter(f => userFunctionalIds.includes(f)).length) {
                            hasAccess = true;
                        }
                    } else {
                        /** NOTE: to prevent an unintentioal blocking of users on production we will grant them access as a fall back
                         *  until guarding by roles is an understood common step in development 
                         */
                        if (environment.production) {
                            hasAccess = true;
                        }

                        console.error(`ROUTER ERROR: Access guard missing 'validRoles' list - ${this.router.url}`);
                    }

                    if (!hasAccess) {
                        this.router.navigate(['/next', 'no-access'], { replaceUrl: true });
                    }

                    return of(hasAccess);
                }
            })
        );

    }

}

