<div *ngFor="let item of transactionData; let i = index"
     class="gs-suggestion-trn-card-content"
     (click)="onTransactionSuggestionClick(item)">
  <div class="gs-suggestion-row">
    <div class="gs-suggestion-trn-info">
      {{item.TransactionNumber}}
    </div>
    <div class="gs-suggestion-trn-period" *ngIf="item.FromDate || item.ToDate">
      {{ (item.FromDate | date:phxConstants.DateFormat.MMM_ddComma_yyyy) || '-'}} -
      {{ (item.ToDate | date:phxConstants.DateFormat.MMM_ddComma_yyyy) || '-' }} </div>
  </div>
  <br/>
  <div class="gs-suggestion-row">
    <div class="gs-suggestion-trn-worker-details"><span
            class="gs-suggestion-trn-worker-name">{{item.WorkerDisplayName || '-'}}</span>
      <span *ngIf="item.SubvendorAgency"> ({{item.SubvendorAgency || '-'}}) </span>
    </div>
    <div class="gs-suggestion-trn-total">
      Bill {{item.BillingCurrencyId | CodeValue:codeValueGroups.Currency:'code'}} ${{ gsUtil.isNum(item.BillingTotal) ? item.BillingTotal : '-'}} <span class="gs-trn-suggestion-pipe">| </span> Pay
      {{item.PaymentCurrencyId | CodeValue:codeValueGroups.Currency:'code'}} ${{ gsUtil.isNum(item.PaymentTotal) ? item.PaymentTotal : '-'}}
    </div>
  </div>
  <br/>
  <div class="gs-suggestion-row">
    <div class="gs-suggestion-trn-clientname"><i class="fa fa-building"></i> {{item.OrganizationClient}}
    </div>
    <div class="gs-suggestion-trn-trnstatus">
      <div class="circle" [ngClass]="{
                'active-status':item.TransactionHeaderStatus === 'Active',
                'processing-status':item.TransactionHeaderStatus === 'New' || item.TransactionHeaderStatus === 'Draft' || item.TransactionHeaderStatus === 'Pending Review',
                'complete-status':item.TransactionHeaderStatus === 'Deleted' || item.TransactionHeaderStatus === 'Reversed'
              }"></div>
      <span class="gs-suggestion-trn-trnstatus">
                {{item.TransactionHeaderStatus || '-'}}</span>
    </div>
  </div>
  <br/>
  <i class="fa fa-search searchicon"></i>
  <div [innerHtml]="item.SearchText" class="searchtext"></div>
</div>
