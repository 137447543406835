
import {of as observableOf,  Observable } from 'rxjs';

import {catchError,  first ,  map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from '../common/services/auth.service';
import { UserProfile, PhxConstants } from '../common/model';

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(private authService: AuthService) {}
  canActivate(): Observable<boolean> {
    return this.authService
      .getCurrentProfile()
      .pipe(
        first(),
        map((profile: UserProfile) => profile?.FunctionalRoles?.some(role => role.FunctionalRoleId === PhxConstants.FunctionalRole.SystemAdministrator))
      ).pipe(
      catchError(() => observableOf(false)));
  }
}
