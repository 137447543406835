<div [class.fieldViewMode]="!removeStyleClasses" [class.form-control-static]="!removeStyleClasses" class="d-inline-block" *ngIf="piiMetaData?.IsMasked; else unmasked_field">{{piiMetaData?.FieldMaskedValue}}</div>
<ng-template #unmasked_field>
    <div [class.fieldViewMode]="!removeStyleClasses" [class.form-control-static]="!removeStyleClasses" class="d-inline-block">{{piiMetaData?.FieldValue}}</div>
</ng-template>
<div class="d-inline-block position-relative">
  <i class="material-icons md-64 eye-icon" (click)="onIconClicked()" *ngIf="piiMetaData?.IsMasked">remove_red_eye</i>
  <div class="popup" *ngIf="showPopup">
    <div class="popup-content">
      <p class="warning-note">
        {{piiMetaData.FieldName}} is sensitive information. Please note that viewing this will be logged.
      </p>
      <ng-container *ngIf="!loadingLastViewedUserInfo; else loadingBar">
        Last viewed:
        <span *ngIf="lastViewedUserData?.LastViewedDate">
          {{lastViewedUserData.LastViewedDate | date:phxConstants.DateFormat.MMM_ddComma_yyyy_HH_mm_ss}}
        </span>
        by {{lastViewedUserData.UserFullName || 'N/A'}}
      </ng-container>
      <ng-template #loadingBar>
        <section class="d-flex">
          Last viewed: <span class="loading-bar flex-grow-1"></span>
        </section>
      </ng-template>

      <div class="text-right button-container">
        <button class="btn btn-default button no-border" type="button" (click)="closePopup()">
          Cancel
        </button>
        <button class="btn btn-primary button" (click)="reveal()">Reveal</button>
      </div>
    </div>
  </div>
</div>
