import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthService } from './common/services/auth.service';
import { AppStorageService, IframeService, PhxLocalizationService } from './common';
import { AppResolver } from './app-resolver.service';
import { AppInitService } from './app-init.service';
import { StorageService } from 'ngx-webstorage-service';
import { ConfigurationService } from './configuration/service/configuration.service';

@Injectable()
export class ExternalAppResolver extends AppResolver {

  constructor(
    appInitService: AppInitService,
    authService: AuthService,
    router: Router,
    iframeService: IframeService,
    localizationService: PhxLocalizationService,
    configurationService: ConfigurationService,
    @Inject(AppStorageService) private storageService: StorageService
  ) {
    super(
      appInitService,
      authService,
      router,
      iframeService,
      localizationService,
      configurationService
    );
  }

  async initApp(route: ActivatedRouteSnapshot): Promise<any> {

    const { locale, token } = route.params;

    this.storageService.set('BearerToken', token);
    this.localizationService.setLocale(locale);

    await this.authService.fetchCurrentUser();
    await this.authService.initApp(locale);
    await this.configurationService.fetchFeatureConfigurationData();

    this.appInitService.loaded = true;

    return true;
  }

}
