import { Injectable } from '@angular/core';
import { from as observableFrom, Observable, Subject, of, forkJoin, lastValueFrom } from 'rxjs';
import { find } from 'lodash';

import { ApiService, CodeValueGroups, CodeValueService, CommonService, PhxConstants } from '../../common';
import {
  IAssignmentDto,
  IBillingInfo,
  IBillingSalesTax,
  IJobOwner,
  IPaymentInfo,
  IPaymentSalesTax,
  IWorkOrder,
  IWorkOrderCreateTransactionPreCheckDto,
  WorkOrderOffboardingStateHistory
} from '../models';
import { SalesTaxVersionRate } from '../../expense/model';
import { EntityList } from '../../common/model';
import { CommissionPickerInputDto, CommissionPickerSummaryDto, CommissionSalesPattern } from '../../commission/model';
import { CommonListsObservableService } from '../../common/lists/lists.observable.service';
import { ICommissionChangeImpactSummary } from '../../commission/commission-change-impact/commission-change-impact.interface';
import { TemplateService } from 'src/app/template/template.service';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { ApplicationTransactionSystemDto } from '../models/dto/application-transaction-system.dto';
import { GlobalConfiguration } from 'src/app/common/model/gobal-configuration/global-configuration';
import { WorkorderInclusionList } from 'src/app/common/model/work-order-inclusion';
import { environment } from 'src/environments/environment';
import { ConfigurationService } from 'src/app/configuration/service/configuration.service';

@Injectable({
  providedIn: 'root'
})
export class WorkorderService {
  private navigationBar$ = new Subject<boolean>();
  private offboardingStatus$ = new Subject<boolean>();
  private refreshWorkOrderSubject = new Subject<any>();

  refreshWorkOrder$ = this.refreshWorkOrderSubject.asObservable();
  branchList = [];

  billingSalesTaxJurisdictions: Array<any>;
  paymentSalesTaxJurisdictions: Array<any> = [];

  private autocalculatedOvertimeInclusionList: number[] | null = null;
  autoCalculateOvertimeIsActiveForWorkorder = false;

  constructor(
    private apiService: ApiService,
    private codeValueService: CodeValueService,
    private commonService: CommonService,
    private commonListsObservableService: CommonListsObservableService,
    private templateService: TemplateService,
    private configurationService: ConfigurationService
  ) { }

  public getWorkOrderCreateAdjustmentTransactionPreCheck(workOrderId: number): Observable<IWorkOrderCreateTransactionPreCheckDto> {
    return this.apiService.query<IWorkOrderCreateTransactionPreCheckDto>(`assignment/getWorkOrderCreateAdjustmentTransactionPreCheck/${workOrderId}`);
  }

  public updateNavigationBar() {
    this.navigationBar$.next(true);
  }

  public navigationBarChange$(): Observable<boolean> {
    return this.navigationBar$.asObservable();
  }

  public updateOffboardingStatus() {
    this.offboardingStatus$.next(true);
  }

  public offboardingStatusChange$(): Observable<boolean> {
    return this.offboardingStatus$.asObservable();
  }
  public getBranchList(): Observable<any> {
    if (this.branchList.length) {
      return of(this.branchList);
    }
    const oDataParams = oreq.request().withSelect(['Id', 'Name', 'IsActive']).url();
    return this.apiService.query(`branch/list?${oDataParams}&`).pipe(tap(branches => (this.branchList = branches)));
  }

  refreshWorkOrder(workorderId: number, assignmentId: number, versionId: number, templateId: number = null) {
    this.refreshWorkOrderSubject.next({
      workorderId,
      assignmentId,
      versionId,
      templateId
    });
  }

  getAssignment(assignmentId: number, workOrderId: number, workOrderVersionId: number) {
    return this.apiService.query<IAssignmentDto>(`assignment/getWorkOrderVersion/${assignmentId}/${workOrderId}/${workOrderVersionId}`);
  }

  getWorkOrderListByWorkerProfileId(userProfileId: number): Observable<EntityList<any>> {
    return this.apiService.query<EntityList<any>>(`assignment/getWorkOrderListByWorkerProfileId/${userProfileId}`);
  }

  getSalesPattern(Id: number): Observable<{ Items: CommissionSalesPattern[] }> {
    const filterResult = oreq.filter('Id').eq(Id);
    const oDataParams = oreq
      .request()
      .withExpand(['CommissionSalesPatternSupporters'])
      .withSelect(['CommissionSalesPatternSupporters/UserProfileId', 'CommissionSalesPatternSupporters/CommissionRoleId', 'CommissionSalesPatternSupporters/FullName'])
      .withFilter(filterResult)
      .url();
    return this.apiService.query('commission/getAllSalesPatterns' + (oDataParams ? '?' + oDataParams : ''), false);
  }

  getWorkOrderVersionCommissionPicker(params: CommissionPickerInputDto) {
    const paramString = JSON.stringify(params);
    return this.apiService.httpGetRequest<Array<CommissionPickerSummaryDto>>(`assignment/getWorkOrderVersionCommissionPicker?workOrderCommissionPickerParams=${paramString}`, undefined, false);
  }

  public getProfilesListOrganizationalByUserProfileType(organizationId: number, userProfileType: PhxConstants.UserProfileType = 0) {
    return this.commonListsObservableService.listUserProfileByTypeAndOrg$(userProfileType || 0, organizationId || 0, false, PhxConstants.ProfileStatus.Inactive);
  }

  public getProfilesListByOrganizationId(organizationId: number) {
    return this.getProfilesListOrganizationalByUserProfileType(organizationId);
  }

  public getProfilesListOrganizational(organizationId: number) {
    return this.getProfilesListOrganizationalByUserProfileType(organizationId, PhxConstants.UserProfileType.Organizational);
  }

  public clientRoleChargeSalesTax(organizationId) {
    return this.apiService.query('org/clientRoleChargeSalesTax/' + organizationId, false);
  }

  public getPaymentReleaseScheduleDetail(paymentReleaseScheduleId, tableState) {
    const oDataParams = this.commonService.generateRequestObject(tableState).url();
    return this.apiService.query('Payment/getPaymentReleaseScheduleDetail/' + paymentReleaseScheduleId + (oDataParams ? '?' + oDataParams : ''));
  }

  public getSalesTaxVersionRatesBySubdivisionAndOrganization(subdivisionIdSalesTax, organizationId) {
    if (subdivisionIdSalesTax && subdivisionIdSalesTax > 0 && organizationId && organizationId > 0) {
      const oDataParams = oreq
        .request()
        .withExpand(['SalesTaxJurisdictions'])
        .withSelect(['Id', 'SalesTaxId', 'DisplayName', 'RatePercentage', 'IsApplied', 'HasNumberAssigned', 'HasJurisdictions', 'SalesTaxJurisdictions/*'])
        .url();
      return this.apiService.query(
        'SalesTaxVersionRate/getSalesTaxVersionRatesBySubdivisionAndOrganization/subdivision/' + subdivisionIdSalesTax + '/Organization/' + organizationId + (oDataParams ? '?' + oDataParams : ''),
        false
      );
    }
  }

  public getRebatesAndFeesDetailsByOriginalAndStatusIsAtiveOrPendingChangeOrganization(organizationId): Observable<any> {
    return this.apiService.query('org/getRebatesAndFeesDetailsByOriginalAndStatusIsAtiveOrPendingChangeOrganization/' + organizationId);
  }
  
  public getPaySideDeductionsByOrganizationId(organizationId,lineOfBusinessId): Observable<any> {
    return this.apiService.query('org/getPaySideDeductions/' + organizationId + '/' + lineOfBusinessId);
  }
  
  getWCBCodesBySubdivisionId(subdivisionId, organizationIdInternal, oDataParams?: any) {
    if (!subdivisionId || !organizationIdInternal) {
      return of(null);
    }
    return this.apiService.query('Payroll/getWCBCodesBySubdivisionId/' + subdivisionId + '/' + organizationIdInternal + '/' + (oDataParams ? '?' + oDataParams : ''));
  }

  getActiveCurrentlyEffectiveProvincialTaxVersionTaxTypeBySubdivisionId(subdivisionId, oDataParams) {
    return this.apiService.query<EntityList<any>>('Payroll/getActiveCurrentlyEffectiveProvincialTaxVersionTaxTypeBySubdivisionId/' + subdivisionId + '/' + (oDataParams ? '?' + oDataParams : ''));
  }

  getWorkOrderPurchaseOrderLinesByWorkOrderId(workOrderId, oDataParams = null) {
    return this.apiService.query('purchaseorder/getWorkOrderPurchaseOrderLineSearchByWorkOrderId/' + workOrderId + (oDataParams ? '?' + oDataParams : ''));
  }

  public getPurchaseOrderLineByOrganizationIdClientAndWorkOrderId(organizationIdClient, workOrderId, oDataParams) {
    return this.apiService.query('purchaseorder/getPurchaseOrderLineByOrganizationIdClientAndWorkOrderId/' + organizationIdClient + '/' + workOrderId + (oDataParams ? '?' + oDataParams : ''));
  }

  public getByPurchaseOrderLineId(purchaseOrderLineId: number, oDataParams: any): Observable<any> {
    return this.apiService.query('purchaseorder/getByPurchaseOrderLineId/' + purchaseOrderLineId + (oDataParams ? '?' + oDataParams : ''));
  }

  public getByPurchaseOrderId(purchaseOrderId: number): Observable<any> {
    return this.apiService.query('purchaseorder?id=' + purchaseOrderId);
  }

  public workOrderPurchaseOrderLineStatusToActivate(command) {
    return observableFrom(this.apiService.command('WorkOrderPurchaseOrderLineStatusToActivate', command));
  }

  public workOrderPurchaseOrderLineDelete(command) {
    return observableFrom(this.apiService.command('WorkOrderPurchaseOrderLineDelete', command));
  }

  public PONewlineSave(command) {
    return observableFrom(this.apiService.command('WorkOrderPurchaseOrderLineCreate', command));
  }

  public updateTemplateBody(command) {
    command.WorkflowPendingTaskId = -1;
    this.scrubTemplateBody(command.TemplateBody);
    return observableFrom(this.apiService.command('UpdateTemplateBody', command));
  }

  // Ideally, both New Template and Update Template would send the same templateBody model. But they don't, so it's any
  public scrubTemplateBody(templateBody: any) {
    this.templateService.changeEntityChain(templateBody, [
      { key: 'Assignment', value: null },
      { key: 'WorkOrder', value: null },
      { key: 'WorkOrderVersion', value: null },
      { key: 'BillingInfo', value: null },
      { key: 'PaymentInfo', value: null },
      { key: 'Organization', value: null },
      { key: 'UserProfile', value: null },
      { key: 'PaymentContact1', value: null },
      { key: 'PaymentContact2', value: null },
      { key: 'CreatedDatetime', value: undefined }, // Can't deserialize to null, exclude from JSON
      { key: 'LastModifiedDatetime', value: undefined }, // Can't deserialize to null, exclude from JSON
      { key: 'WorkOrderPurchaseOrderLines', value: [] },
      { key: 'CommissionAttribution', value: null },
      { key: 'CommissionRateVersions', value: [] },
      { key: 'OverrideTimesheetExceptions', value: '[]' },
      { key: 'RootObject', value: undefined },
      { key: 'Id', value: 0 },
      { key: 'IsDraft', value: true },
      { key: 'AssignmentId', value: 0 },
      { key: 'WorkOrderId', value: 0 },
      { key: 'WorkOrderVersionId', value: 0 },
      { key: 'BillingInfoId', value: 0 },
      { key: 'PaymentInfoId', value: 0 },
      { key: 'SourceId', value: null },
      { key: 'LastModifiedByProfileId', value: 0 },
      { key: 'CreatedByProfileId', value: 0 },
      { key: 'IsCommissionChangeImpactApproved', value: null },
      { key: 'UserProfileIdSupplier', value: null }
    ]);
  }

  public getApplicationConfigurationByTypeId(typeid, oDataParams) {
    return this.apiService.query('config/getApplicationConfigurationByTypeId/' + typeid + (oDataParams ? '?' + oDataParams : ''));
  }

  getActiveCurrentlyEffectiveFederalTaxVersionBySubdivisionId(subdivisionId, oDataParams) {
    return this.apiService.query('Payroll/getActiveCurrentlyEffectiveFederalTaxVersionBySubdivisionId/' + subdivisionId + (oDataParams ? '?' + oDataParams : ''));
  }

  getAts(atsSourceId: number, atsPlacementId: number, showLoader = true): Observable<ApplicationTransactionSystemDto> {
    return this.apiService.query('assignment/getAts/atsSourceId/' + atsSourceId + '/atsPlacementId/' + atsPlacementId, showLoader);
  }

  getTemplatesByEntityTypeId(entityTypeId): Observable<any> {
    const filter = oreq.filter('StatusId').eq(1);
    const params = oreq.request().withFilter(filter).url();
    return this.apiService.query('template/getTemplatesByEntityTypeId/' + entityTypeId + '?' + params);
  }

  workOrderNew(command) {
    return observableFrom(this.apiService.command('AssignmentCreateState', command));
  }

  getWorkOrderOffboardingStateHistory(workOrderId, showLoader: boolean = true): Promise<WorkOrderOffboardingStateHistory> {
    return lastValueFrom(this.apiService.query<WorkOrderOffboardingStateHistory>(`state/getWorkOrderOffboardingStateHistory/${workOrderId}`, showLoader));
  }

  getDuplicateAtsWorkOrder(atsSourceId, atsPlacementId) {
    const oDataQuery = oreq
      .request()
      .withSelect(['WorkOrderFullNumber', 'WorkerName', 'StartDate', 'EndDate', 'WorkOrderStatus', 'AssignmentId', 'WorkOrderId', 'WorkOrderVersionId'])
      .withFilter(oreq.filter('AtsSourceId').eq(atsSourceId).and().filter('AtsPlacementId').eq(atsPlacementId).and().filter('WorkOrderStatusId').ne(PhxConstants.WorkOrderStatus.Cancelled))
      .url();
    return this.apiService.query('assignment/getSearch?' + oDataQuery);
  }

  public templateNew(command) {
    command.WorkflowPendingTaskId = -1;
    this.scrubTemplateBody(command.TemplateBody);
    return observableFrom(this.apiService.command('TemplateNew', command));
  }

  getTerminationTypeAndReason(userProfileTypeId: number): Observable<any> {
    return this.apiService.query('assignment/getTerminationTypesAndReasons/' + userProfileTypeId);
  }

  public get(id) {
    if (id && !isNaN(id)) {
      return this.apiService.query('template/' + id);
    } else {
      return this.apiService.query('template');
    }
  }

  getWorker(assignment, listUserProfileWorker) {
    let worker = null;
    if (assignment.UserProfileIdWorker > 0) {
      worker = find(listUserProfileWorker, w => w.Id === assignment.UserProfileIdWorker);
      if (typeof worker !== 'undefined') {
        assignment.workerProfileTypeId = worker.ProfileTypeId;
        assignment.workerContactId = worker.ContactId;
      } else {
        assignment.workerProfileTypeId = null;
        worker = null;
      }
    } else {
      assignment.workerProfileTypeId = null;
      assignment.workerContactId = null;
    }
    return worker;
  }

  public getSubdivisionIdByWorksiteId(worksiteId: number) {
    return worksiteId ? this.codeValueService.getParentId(CodeValueGroups.Worksite, worksiteId) : null;
  }

  public getSalesTaxVersionRatesBySubdivisionAndUserProfileWorker(subdivisionIdSalesTax: number, profileId: number) {
    const oDataParams = oreq
      .request()
      .withExpand('SalesTaxJurisdictions')
      .withSelect(['Id', 'SalesTaxId', 'DisplayName', 'RatePercentage', 'IsApplied', 'HasNumberAssigned', 'HasJurisdictions', 'SalesTaxJurisdictions/*'])
      .url();
    return this.apiService.query(
      'SalesTaxVersionRate/getSalesTaxVersionRatesBySubdivisionAndUserProfileWorker/Subdivision/' + subdivisionIdSalesTax + '/Profile/' + profileId + (oDataParams ? '?' + oDataParams : ''),
      false
    );
  }

  async getPaymentSalesTaxes(paymentInfo: IPaymentInfo, index: number, userProfileIdWorker: number, workerProfileTypeId: number): Promise<Array<IPaymentSalesTax>> {
    const paymentSalesTaxes: Array<IPaymentSalesTax> = [];
    const subdivisionIdSalesTax = paymentInfo ? paymentInfo.SubdivisionIdSalesTax : null;
    const organizationIdSupplier = paymentInfo ? paymentInfo.OrganizationIdSupplier : null;
    if (subdivisionIdSalesTax) {
      let responseSalesTaxVersionRates: any;
      if (organizationIdSupplier) {
        responseSalesTaxVersionRates = await lastValueFrom(this.getSalesTaxVersionRatesBySubdivisionAndOrganization(subdivisionIdSalesTax, organizationIdSupplier));
      } else if (userProfileIdWorker && workerProfileTypeId === PhxConstants.UserProfileType.WorkerCanadianSp) {
        responseSalesTaxVersionRates = await lastValueFrom(this.getSalesTaxVersionRatesBySubdivisionAndUserProfileWorker(subdivisionIdSalesTax, userProfileIdWorker));
      }
      const salesTaxVersionRates: Array<SalesTaxVersionRate> = (responseSalesTaxVersionRates ? responseSalesTaxVersionRates.Items : null) || [];

      if (paymentInfo.JurisdictionId) {
        const jurisdiction = salesTaxVersionRates.filter(st => st.HasJurisdictions).map(st => st.SalesTaxJurisdictions.find(j => j.JurisdictionId === paymentInfo.JurisdictionId))[0];
        if (jurisdiction.IsApplied) {
          const versionRate = salesTaxVersionRates.find(st => st.Id === jurisdiction.SalesTaxVersionRateId);
          paymentSalesTaxes.push({
            Id: versionRate.Id,
            SalesTaxId: versionRate.SalesTaxId,
            DisplayName: versionRate.DisplayName + ' - ' + jurisdiction.DisplayName,
            IsApplied: versionRate.HasNumberAssigned,
            ratePercentage: jurisdiction.RatePercentage,
            hasNumber: versionRate.HasNumberAssigned
          } as IPaymentSalesTax);
        }
      }

      let paymentJurisdictions = [];
      salesTaxVersionRates.forEach((rate: SalesTaxVersionRate) => {
        if (rate.HasJurisdictions && rate.SalesTaxJurisdictions) {
          paymentJurisdictions = paymentJurisdictions.concat(rate.SalesTaxJurisdictions);
        }
        if (!paymentInfo.JurisdictionId) {
          paymentSalesTaxes.push({
            Id: rate.Id,
            SalesTaxId: rate.SalesTaxId,
            DisplayName: rate.DisplayName,
            IsApplied: rate.HasNumberAssigned,
            ratePercentage: rate.RatePercentage,
            hasNumber: rate.HasNumberAssigned
          } as IPaymentSalesTax);
        }
      });
      this.paymentSalesTaxJurisdictions[index] = paymentJurisdictions;
    }
    return paymentSalesTaxes;
  }

  async getBillingSalesTaxes(billingInfo: IBillingInfo, organizationIdInternal: number, isAppliedCalc: boolean = true): Promise<Array<IBillingSalesTax>> {
    const promises = [];
    if (billingInfo.SubdivisionIdSalesTax && billingInfo.OrganizationIdClient && organizationIdInternal) {
      promises.push(lastValueFrom(this.getSalesTaxVersionRatesBySubdivisionAndOrganization(billingInfo.SubdivisionIdSalesTax, organizationIdInternal)));
      if (isAppliedCalc) {
        promises.push(lastValueFrom(this.clientRoleChargeSalesTax(billingInfo.OrganizationIdClient)));
      }
    }
    const data = await Promise.all(promises);
    const billingSalesTaxes: Array<IBillingSalesTax> = [];
    const responseSalesTaxVersionRates = data ? data[0] : null;
    const salesTaxVersionRates: Array<SalesTaxVersionRate> = (responseSalesTaxVersionRates ? responseSalesTaxVersionRates.Items : null) || [];
    const isApplied = data?.[1] ?? true;

    if (billingInfo.JurisdictionId) {
      const jurisdiction = salesTaxVersionRates.filter(st => st.HasJurisdictions).map(st => st.SalesTaxJurisdictions.find(j => j.JurisdictionId === billingInfo.JurisdictionId))[0];
      if (jurisdiction.IsApplied) {
        const versionRate = salesTaxVersionRates.find(st => st.Id === jurisdiction.SalesTaxVersionRateId);
        billingSalesTaxes.push({
          Id: versionRate.Id,
          SalesTaxId: versionRate.SalesTaxId,
          DisplayName: versionRate.DisplayName + ' - ' + jurisdiction.DisplayName,
          IsApplied: isApplied && versionRate.HasNumberAssigned,
          ratePercentage: jurisdiction.RatePercentage,
          hasNumber: versionRate.HasNumberAssigned
        } as IBillingSalesTax);
      }
    }

    this.billingSalesTaxJurisdictions = [];
    salesTaxVersionRates.forEach((rate: SalesTaxVersionRate) => {
      if (rate.HasJurisdictions && rate.SalesTaxJurisdictions) {
        this.billingSalesTaxJurisdictions = this.billingSalesTaxJurisdictions.concat(rate.SalesTaxJurisdictions);
      }
      if (!billingInfo.JurisdictionId) {
        billingSalesTaxes.push({
          Id: rate.Id,
          SalesTaxId: rate.SalesTaxId,
          DisplayName: rate.DisplayName,
          IsApplied: isApplied && rate.HasNumberAssigned,
          ratePercentage: rate.RatePercentage,
          hasNumber: rate.HasNumberAssigned
        } as IBillingSalesTax);
      }
    });

    return billingSalesTaxes;
  }

  getWorkOrderTerminationComment(EntityTypeId: number, EntityId: number, showLoader: boolean = true): Promise<string> {
    return lastValueFrom(this.apiService.query<string>(`assignment/getWorkOrderTerminationComment/EntityTypeId/${EntityTypeId}/EntityId/${EntityId}`, showLoader));
  }

  getCommissionChangeImpactForWov(wovId: number): Promise<ICommissionChangeImpactSummary> {
    return lastValueFrom(this.apiService.query<ICommissionChangeImpactSummary>('commission/getCommissionChangeImpact/' + wovId));
  }

  getApprovedCommissionChangeImpactForWov(wovId: number): Promise<ICommissionChangeImpactSummary> {
    const entityTypeId = PhxConstants.EntityType.WorkOrderVersion;
    return lastValueFrom(this.apiService.query<ICommissionChangeImpactSummary>(`commission/getApprovedCommissionChangeImpact/EntityTypeId/${entityTypeId}/EntityId/${wovId}`));
  }

  public isWorkOrderVersionCommissionsChanged(wovCommissions: IJobOwner[], originalWovCommissions: IJobOwner[], workOrder: IWorkOrder): boolean {
    if (workOrder?.StatusId === PhxConstants.WorkOrderStatus.Processing) {
      return false;
    }
    if (originalWovCommissions?.length === 0) {
      return false;
    }

    if (!this.areWorkOrderVersionCommissionProfilesEqual(wovCommissions, originalWovCommissions)) {
      return true;
    }
    return !this.areWorkOrderVersionCommissionRateHeadersEqual(wovCommissions, originalWovCommissions);
  }

  public getIsRecruitersAllowed(lob: PhxConstants.LineOfBusiness) {
    return lob === PhxConstants.LineOfBusiness.Regular || lob === PhxConstants.LineOfBusiness.SubVendorPlacement || lob === PhxConstants.LineOfBusiness.DirectSourcing;
  }

  private areWorkOrderVersionCommissionProfilesEqual(newWovCommissions: IJobOwner[], originalWovCommissions: IJobOwner[]): boolean {
    if (this.areWovCommissionsSameLength(newWovCommissions, originalWovCommissions)) {
      const result = newWovCommissions.filter(obj1 => {
        return !originalWovCommissions.some(obj2 => {
          return obj1.UserProfileIdSales === obj2.UserProfileIdSales && obj1.CommissionRoleId === obj2.CommissionRoleId;
        });
      });
      return result.length <= 0;
    }
    return false;
  }

  private areWorkOrderVersionCommissionRateHeadersEqual(newWovCommissions: IJobOwner[], originalWovCommissions: IJobOwner[]): boolean {
    if (this.areWovCommissionsSameLength(newWovCommissions, originalWovCommissions)) {
      const result = newWovCommissions.filter(obj1 => {
        return !originalWovCommissions.some(obj2 => {
          return obj1.CommissionRateHeaderId === obj2.CommissionRateHeaderId;
        });
      });
      return result.length <= 0;
    }
    return false;
  }

  private areWovCommissionsSameLength(newWovCommissions: IJobOwner[], originalWovCommissions: IJobOwner[]): boolean {
    return newWovCommissions && originalWovCommissions && newWovCommissions.length === originalWovCommissions.length;
  }

  isAutocalculatingFeatureActiveForWorkorder(assignmentId: number | null): Observable<boolean> {
    if (!assignmentId) {
      return of(false);
    }

    return this.configurationService.isFeatureActive$([PhxConstants.FeatureFlags.AutocalculateEveryonesOvertime]).pipe(
      take(1),
      switchMap(featureFlagState => {
        const autocalculateEveryonesOvertime = featureFlagState[PhxConstants.FeatureFlags.AutocalculateEveryonesOvertime];
        /** NOTE: if autocalculate is on for everyone - no need to get the exclusion list */
        return forkJoin([
          autocalculateEveryonesOvertime ? of([]) : this.getIncludedAssignmentIdsForAutocalculate(),
          of(autocalculateEveryonesOvertime)
        ]);
      }),
      map(([assignmentIds, autocalculateEveryonesOvertime]) => {
        return autocalculateEveryonesOvertime ? true : assignmentIds.includes(assignmentId);
      }),
      catchError(() => of(false))
    );
  }

  private getIncludedAssignmentIdsForAutocalculate(): Observable<number[]> {
    if (!this.autocalculatedOvertimeInclusionList) {
      return this.apiService.httpGetRequest<GlobalConfiguration<WorkorderInclusionList>[]>('globalConfiguration/FeatureConfiguration/AutocalculateOvertimeAssignmentInclusionList',
        environment.adminServiceApiEndpoint, false).pipe(
          map(woInclusionData => woInclusionData?.[0]?.Data?.Ids ?? []),
          catchError(() => of([])
          )
        );
    } else {
      return of(this.autocalculatedOvertimeInclusionList);
    }
  }

}
