import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from 'src/app/common/ngx-strongly-typed-forms/model';
import { Validators } from '@angular/forms';
import { AuthService } from '../../common/services/auth.service';
import { AppResolver } from 'src/app/app-resolver.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PhxConstants, PhxLocalizationService } from 'src/app/common';
import { AccountModuleResourceKeys } from './../account-module-resource-keys';
import { UrlData } from 'src/app/common/services/urlData.service';
import { BaseComponentOnDestroy } from 'src/app/common/epics/base-component-on-destroy';
import { takeUntil } from 'rxjs/operators';

export interface IAccessCodeLogin {
  accessCode: string;
}

@Component({
  selector: 'app-access-code',
  templateUrl: './access-code.component.html',
  styleUrls: ['./access-code.component.less']
})
export class AccessCodeComponent extends BaseComponentOnDestroy implements OnInit {

  form: FormGroup<IAccessCodeLogin>;

  loading: boolean = true;

  loginHideMessage: string;

  tokenObj: { IsExistingUser: boolean; token: string };

  loginFailedMessage: string;

  accountResourceKeys = AccountModuleResourceKeys;

  constructor(private fb: FormBuilder, private authService: AuthService, private localizationService: PhxLocalizationService,
    private appResolver: AppResolver, private router: Router, private route: ActivatedRoute, private urlData: UrlData) {
    super();
  }

  ngOnInit() {
    this.form = this.fb.group<IAccessCodeLogin>({
      accessCode: [null, [
        Validators.required
      ]]
    });

    this.route.params.pipe(takeUntil(this.isDestroyed$)).subscribe(params => {
      this.loading = true;
      this.tokenObj = null;
      this.loginFailedMessage = null;
      this.loginHideMessage = null;

      const token = params.token;

      this.authService.validateAccessCodeToken(token)
        .pipe(takeUntil(this.isDestroyed$))
        .subscribe({
          next: (response) => {
            this.tokenObj = response;
            this.tokenObj.token = token;
            this.loading = false;
          },
          error: (ex => {
            if (ex.error?.Message) {
              this.loginHideMessage = ex.error.Message;
            } else {
              this.loginHideMessage = this.localizationService.translate(this.accountResourceKeys.accessCode.invalidToken);
            }
            this.loading = false;
          })
        });
    });
  }

  submit() {
    this.loginFailedMessage = null;

    this.authService.accessCode(this.form.value.accessCode, this.tokenObj.token)
      .then(response => {
        this.appResolver.initApp().then(() => {
          if (response.entityTypeId === PhxConstants.EntityType.TimeSheet) {
            this.urlData.setUrl('next/activity-centre');
            this.router.navigate([`next/timesheet/${response.entityId}`]);
          } else {
            this.router.navigate([`next/activity-centre`]);
          }
        });
      })
      .catch(ex => {
        if (ex.error?.Message) {
          this.loginFailedMessage = ex.error.Message;
        } else {
          this.loginFailedMessage = this.localizationService.translate(this.accountResourceKeys.login.loginFailedMessage);
        }
      });
  }

}
