<dx-select-box
  #selectBox
  [dataSource]="dataSource"
  [value]="value"
  [grouped]="grouped"
  [placeholder]="placeholder"
  [showClearButton]="showClearButton"
  [readOnly]="readOnly"
  [disabled]="disabled"
  [displayExpr]="textField"
  [valueExpr]="valueField"
  [searchEnabled]="searchable"
  [acceptCustomValue]="acceptCustomValue"
  [width]="width"
  [maxLength]="maxLength"
  (onValueChanged)="onValueChanged($event)"
  (onCustomItemCreating)="onCustomItemCreating($event)"
  (onOpened)="onOpened($event)"
  [searchExpr]="searchField"
>
  <ng-container *ngIf="itemTemplateRef && itemTemplate === 'item'">
    <ng-container *dxTemplate="let itemData of 'item'; let itemIndex = index">
      <ng-container *ngTemplateOutlet="itemTemplateRef; context: { itemData: itemData, itemIndex: itemIndex }"> </ng-container>
    </ng-container>
  </ng-container>
</dx-select-box>
