import { Component, Input, OnInit } from '@angular/core';
import { CommonService, PhxConstants } from '../../../../common';
import { GlobalSearchService } from '../../../services/global-search-service';

@Component({
  selector: 'app-global-search-suggestion-assignment',
  templateUrl: './global-search-suggestion-assignment.component.html',
  styleUrls: ['./global-search-suggestion-assignment.component.less']
})
export class GlobalSearchSuggestionAssignmentComponent implements OnInit {
  @Input() assignmentData;

  constructor(private commonService: CommonService, private globalSearchService: GlobalSearchService) {
  }

  get dateFormatMMM_ddComma_yyyy() {
    return PhxConstants.DateFormat.MMM_ddComma_yyyy;
  }

  ngOnInit() {
  }

  getProfileTypeForDisplay(profileType) {
    return this.commonService.getUserProfileTypeFromProfileTypeForCardDisplay(profileType);
  }

  onWorkOrderSuggestionClick(item) {
    this.globalSearchService.onWorkOrderSuggestionClick(item);
  }
}
