import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { PhxConstants } from '../../model';
import UserProfileType = PhxConstants.UserProfileType;

@Injectable()
export class CanActivateProfileForReportGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise(async (resolve, reject) => {
      try {
        const user = await this.authService.fetchCurrentProfile();
        if (user) {
          // Internal User
          if (user.ProfileTypeId === UserProfileType.Internal) {
            return resolve(true);
          } else {
            const allowedReportsToExternalUser: Array<string> = ['18' // Payment Transaction
              , '21' // Expense
              , '22'  // Invoice
              , '31']; // Timesheet

            if (allowedReportsToExternalUser.some(reportId => reportId === route.params.reportId)) {
              return resolve(true);
            }
          }
        }
        this.router.navigate(['/next', 'no-access'], { replaceUrl: true });
        return resolve(false);
      } catch (e) {
        reject(e);
      }
    });
  }
}
