<div *ngIf="isDocumentView" style="display: flex; justify-content: center; align-items: center;">
    <button type="button" class="btn btn-default"
    (click)="reAnalyzeDocument()">
        Re-analyze document
    </button>
</div>

<table class="table table-striped">
    <thead>
        <tr>
            <th class="icon-cell"></th>
            <th class="text-cell"></th>
        </tr>
    </thead>
    <tbody>
        <ng-container *ngFor="let rule of complianceData.documentValidationRuleResults; let idx = index">
            <tr>
                <td>
                    <mat-icon class="ck-success" *ngIf="rule.isValid">done</mat-icon>
                    <mat-icon class="ck-warn"
                        *ngIf="warningStatusList.includes(rule.executedRuleLevel) && !rule.isValid">warning</mat-icon>
                    <mat-icon class="ck-critical"
                        *ngIf="errorStatusList.includes(rule.executedRuleLevel) && !rule.isValid">close</mat-icon>
                </td>
                <td>
                    {{rule.infoMessage}}
                    <ng-container *ngIf="rule.operation">
                        <i [id]="'ruleinfo-'+ idx" class="material-icons md-18 icon-tooltip"
                            [class.phx-dx-hover-only]="true">info</i>
                        <app-phx-tool-tip id="tooltip-component-auto" [targetId]="'#ruleinfo-' + idx" position="right">
                            <table class="info-table">
                                <tr>
                                    <td i18n="@@complianceDocumentData.fboValue">Expected Value:</td>
                                    <td>{{rule.providedValue.join(', ')}}</td>
                                </tr>
                                <tr>
                                    <td i18n="@@complianceDocumentData.gorillavisionValue">Found Value:</td>
                                    <td>{{rule.extractedValue}}</td>
                                </tr>
                            </table>
                        </app-phx-tool-tip>
                    </ng-container>
                </td>
            </tr>
        </ng-container>
    </tbody>
</table>