<div *ngFor="let payment of paymentData; let i = index"
     class="gs-suggestion-payment-card-content"
     (click)="onPaymentSuggestionClick(payment)">
  <div class="gs-suggestion-row">
    <div class="gs-suggestion-payment-info">
      {{payment.PaymentNumber || '-'}} <span class="gs-payment-suggestion-pipe">| </span>
      {{payment.PaymentMethodId | CodeValue:codeValueGroups.PaymentMethodType}}
    </div>
    <div class="gs-suggestion-payment-period" *ngIf="payment.PaymentReleaseDate">
      {{ (payment.PaymentReleaseDate | date:phxConstants.DateFormat.MMM_ddComma_yyyy) || '-'}} </div>
  </div>
  <br/>
  <div class="gs-suggestion-row">
    <div class="gs-suggestion-payment-worker-details"><span
            class="gs-suggestion-payment-worker-name">{{gsUtil.firstPlusCount(payment.WorkerName) || '-'}}</span>
      <span *ngIf="payment.SubvendorAgency"> ({{payment.SubvendorAgency || '-'}}) </span>
    </div>
    <div class="gs-suggestion-payment-total">
      {{payment.CurrencyId |  CodeValue:codeValueGroups.Currency:'code'}}
      ${{ gsUtil.isNum(payment.PaymentTotal) ? payment.PaymentTotal : '-'}}
    </div>
  </div>
  <br/>
  <div class="gs-suggestion-row">
    <div class="gs-suggestion-payment-clientname"><i class="fa fa-building"></i> {{payment.ClientName || '-'}}
    </div>
    <div class="gs-suggestion-payment-paymentstatus">
      <div class="circle" [ngClass]="{
              'active-status':payment.Status === 'Cheque Released' || payment.Status === 'Released',
                        'processing-status':payment.Status === 'Pending Release'
              }"></div>

      <span class="gs-suggestion-payment-paymentstatus">{{payment.Status || '-'}}</span>
    </div>
  </div>
  <br/>
  <i class="fa fa-search searchicon"></i>
  <div [innerHtml]="payment.SearchText" class="searchtext"></div>
</div>
