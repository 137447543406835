import { Component, OnInit } from '@angular/core';
import { BaseComponentOnDestroy } from 'src/app/common/epics/base-component-on-destroy';
import { takeUntil } from 'rxjs';
import { NavigationService } from 'src/app/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-demo-iframe',
  templateUrl: './demo-iframe.component.html',
  styleUrls: ['./demo-iframe.component.less']
})
export class DemoIframeComponent extends BaseComponentOnDestroy implements OnInit {
  iframeSource: string | null;

  constructor(
    private navigationService: NavigationService,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.navigationService.updateBreadCrumbState(false);

    this.navigationService.demoIframeUri$.pipe(
      takeUntil(this.isDestroyed$),
    ).subscribe(uri => {
      if (!uri) {
        this.router.navigate(['/next/account/home']);
      } else {
        this.iframeSource = uri;
      }
    });
  }


}
