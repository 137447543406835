import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { MatSnackBarData, PhxMatToastComponent } from '../components/phx-mat-toast/phx-mat-toast.component';
import { MatSnackBarHorizontalPosition } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private defaultDuration = 6000;

  constructor(private snackBar: MatSnackBar) { }

  logInfo(message, keepOpen: boolean = false) {
    this.showToast(message, 'info', 'info', keepOpen ? 0 : this.defaultDuration);
  }

  logWarning(message, keepOpen: boolean = false) {
    this.showToast(message, 'warning', 'warning', keepOpen ? 0 : this.defaultDuration);
  }

  logSuccess(message, keepOpen: boolean = false) {
    this.showToast(message, 'done', 'success', keepOpen ? 0 : this.defaultDuration);
  }

  logError(message, keepOpen: boolean = false) {
    this.showToast(message, 'error', 'error', keepOpen ? 0 : this.defaultDuration);
  }

  logErrorUserDismissed(message) {
    this.showToast(message, 'error', 'error', 0);
  }

  showWarningToastNoIcon(message, keepOpen: boolean = false) {
    this.showToast(message, null, 'warning', keepOpen ? 0 : 25000);
  }

  showToast(
    message: string,
    icon: string,
    panelClass: 'success' | 'error' | 'info' | 'warning',
    duration: number = this.defaultDuration,
    action: string = '',
    horizontalPosition: MatSnackBarHorizontalPosition = 'right'
  ): MatSnackBarRef<PhxMatToastComponent> {
    return this.snackBar.openFromComponent(PhxMatToastComponent, {
      data: {
        message,
        icon,
        action,
      } as MatSnackBarData,
      duration,
      horizontalPosition,
      panelClass,
    });
  }
}
