import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DocumentComplianceData } from '../../model/document-compliance/document-compliance-data';
import { DocumentComplianceEntityInformation } from '../../model/document-compliance/document-compliance-entity-information';
import { from } from 'rxjs';
import { ApiService, CommonService } from '../../../common';

@Component({
  selector: 'app-phx-document-file-compliance',
  templateUrl: './phx-document-file-compliance.component.html',
  styleUrls: ['./phx-document-file-compliance.component.less']
})
export class PhxDocumentFileComplianceComponent {
  @Input() complianceData: DocumentComplianceData;
  @Input() isDocumentView = false;
  @Input() additionalInformation: DocumentComplianceEntityInformation = null;
  @Input() complianceDocumentId: number;
  @Input() documentName: string = null;
  @Output() complianceDataUpdated = new EventEmitter<{ documentName: string, filePublicId: string }>();

  warningStatusList = ['Warning', 'Info'] as const;
  errorStatusList = ['Error', 'Critical', 'Fatal'] as const;

  constructor(
    private readonly commonService: CommonService,
    private readonly apiService: ApiService
  ) {}

  reAnalyzeDocument() {
    this.additionalInformation.FilePublicId = this.complianceData.filePublicId;
    const commandRequest = {
      CommandName: 'ComplianceDocumentRunRule',
      ComplianceDocumentId: this.complianceDocumentId,
      AdditionalInformation: JSON.stringify(this.additionalInformation)
    };
    let html = `<div class='gvlogo-for-toast'>`;
    html += `<div class='gv-logo-container'><img src="/assets/logos/gv-logo.svg"></div>`;
    html += `<div class='text-container'>Analyzing <i>${this.documentName}</i></div>`;
    html += `<div>`;

    this.commonService.logWarningNoIcon(html, true);
    from(this.apiService.command('ComplianceDocumentRunRule', commandRequest, false))
    .subscribe({
      next: () => {
        this.complianceDataUpdated.emit({ documentName: this.documentName, filePublicId: this.additionalInformation.FilePublicId });
      },
      error: () => {
        this.commonService.logError('Error re-analyzing document compliance rules');
      }
    });
  }
}
